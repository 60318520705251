/* Шрифты не заменяются, а собираются как Interface в typescript */
@font-face {
	font-family: 'Inter';
	src: url('../fonts/inter.woff2') format('woff2'),
	url('../fonts/inter.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'InterBold';
	src: url('../fonts/inter_bold.woff2') format('woff2'),
	url('../fonts/inter_bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

